const HeaderBar = () => import('../components/HeaderBar.vue');

const defaultComponents = {
  HeaderBar,
};

export default [
  {
    path: '/',
    name: 'home',
    meta: {
      requiresAuth: true,
      title: 'Home',
    },
    components: {
      ...defaultComponents,
      default: () => import('../pages/Courses.vue'),
    },
  },
  {
    path: '/courses',
    name: 'courses',
    meta: {
      requiresAuth: true,
      title: 'Home',
    },
    components: {
      ...defaultComponents,
      default: () => import('../pages/Courses.vue'),
    },
  },
  {
    path: '/profile',
    name: 'profile',
    meta: {
      requiresAuth: true,
      title: 'Update Profile',
    },
    components: {
      ...defaultComponents,
      default: () => import('../pages/UpdateProfile.vue'),
    },
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      lobby: true,
      title: 'Login',
    },
    components: {
      ...defaultComponents,
      default: () => import('../pages/Login.vue'),
    },
  },
  {
    path: '/login/default',
    name: 'login-default',
    meta: {
      title: 'Login',
    },
    components: {
      ...defaultComponents,
      default: () => import('../pages/LoginDefault.vue'),
    },
  },
  {
    path: '/logout',
    name: 'logout',
    components: {
      ...defaultComponents,
      default: () => import('../pages/Logout.vue'),
    },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    meta: {
      title: 'Reset Password',
    },
    components: {
      ...defaultComponents,
      default: () => import('../pages/ResetPassword.vue'),
    },
  },
  {
    path: '/course/:id(\\d+)?',
    name: 'course-player',
    meta: {
      bypassIf: (to: {query: {token: string}}) => to.query.token,
      requiresAuth: true,
      coursePageHeader: true,
      title: 'Course Player',
      showSplash: false,
    },
    components: {
      default: () => import('../pages/CoursePlayer.vue'),
    },
  },
  {
    path: '/course/error',
    name: 'course-error',
    meta: {
      title: 'Course Playback Error',
    },
    components: {
      ...defaultComponents,
      default: () => import('../pages/CoursePlayerError.vue'),
    },
  },
  {
    path: '/login/error',
    name: 'login-error',
    meta: {
      title: 'Login Error',
    },
    components: {
      ...defaultComponents,
      default: () => import('../pages/LoginError.vue'),
    },
  },
  {
    path: '/user/trial/registration',
    name: 'trial-registration',
    meta: {
      title: 'Trial User Registration',
    },
    components: {
      ...defaultComponents,
      default: () => import('../pages/TrialUserRegistration.vue'),
    },
  },
  {
    path: '/campaigns/unsubscribe',
    name: 'campaigns-unsubscribe',
    meta: {
      title: 'Unsubscribe Campaign',
    },
    components: {
      ...defaultComponents,
      default: () => import('../pages/CampaignsUnsubscribe.vue'),
    },
  },
  {
    path: '/access-denied',
    name: 'access-denied',
    meta: {
      title: 'Access denied',
    },
    components: {
      ...defaultComponents,
      default: () => import('../pages/400View.vue'),
    },
  },

  // this must be last so it catches 404s
  {
    path: '/:pathMatch(.*)*',
    component: () => import('../pages/404.vue'),
  },
];
